<template>
  <div class="Dashboard">

	<p class="h6 text-center text-primary"><b>Dashboard - {{dbNbr}}</b></p>    

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>

	<p class="text-danger" align="left">{{alert}}</p>

	<vue-good-table
		ref="dbTable"
		:columns="columns"
		:rows="rows"
		:line-numbers="true"
		:row-style-class="rowStyleClassFn"
		:sort-options="{
			enabled: false,
		}"
		styleClass="vgt-table condensed"
	>
	</vue-good-table>
	


	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';

export default {
	name: 'db-itemwise',

    components: {
        ScaleLoader,
	},
		
	data () {

		return {
			dbNbr: 0,
			orgId: null,

			columns: [],
			rows: [],

			alert: null,
			loading: false
		};
	},

	computed: {
	},


	mounted(){
		this.dbNbr = this.$route.params['dbNbr']
		this.orgId = this.$route.params['orgId']
		this.serverCall('getDashboard')
	},

	methods: {
		rowStyleClassFn(row) {
			if (row.rowColor == 1){
				return 'blue'
			}
		},

		// https://jsfiddle.net/aks9800/ou1cLpy8/

		// getCellClass(row, field) {
		// 	console.log(row)
		// 	if (row.originalIndex > 0)
		// 	console.log(field)
		// 	return 22 > 21 ? 'yellow' : '';
		// },

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getDashboard":
								self.getDashboard(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Dashboard - Please login first."
				}
			})
		},
		

        // Get Dashboard Data
        getDashboard(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Dashboard Data..."

			var formData = new FormData()
			formData.append("dbNbr", this.dbNbr)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetDashboard/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': this.orgId,
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getDashboard - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.rows
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.red {
		background: red;
		color: white;
	}

	.text-bold {
		font-weight: bold;
	}

	.active {
		color : green;
	}

	.normal-whitespace {
		white-space: normal;
	}

</style>

